import React, { useState, useEffect, useRef } from 'react'
import Seo from '../components/seo.js'
// ECMA Script Polyfills:
import "core-js/stable";
// Needed for the generator functions which are transpiled from your async await keywords
import 'regenerator-runtime/runtime'
import * as typeformEmbed from '@typeform/embed'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"
import { Widget } from '@typeform/embed-react'
import style from './formularz.css'
import TransitionLink from 'gatsby-plugin-transition-link'
import platon1 from '../images/formularz/platon_klient.png'
import platon2 from '../images/formularz/platon_pracownik.png'
import platon3 from '../images/formularz/platon_partner@2x.png'
import mapa from '../images/formularz/mapa.jpg'
import kontakt from '../images/seo/kontakt.jpg'
import telefon from '../images/ikony/telefon.svg'
import fb from '../images/ikony/fb.svg'
import ig from '../images/ikony/ig.svg'
import zamknij from '../images/ikony/zamknij.svg'

import strzalka from '../images/ikony/strzalka_mala.svg'


class Formularz extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      wjazd: false,
      typeform: false,
      mobile: false,
      partner: false,
      pracownik: false,
      klient: false,
    }
    this.el = null;
    this.check = React.createRef();

    this.k = React.createRef();
    this.p = React.createRef();
    this.pr = React.createRef();
  }





  componentDidMount(){
    this.setState({
      wjazd: true,
    })
    this.domRect = this.check.current.getBoundingClientRect()
    if(this.domRect.width < 1025) {
      this.setState({
          mobile: true,
          wjazd: true,
      })
      console.log("TO JEST MOBILE");
    }
    else {
      this.setState({
        wjazd: true,
    })
    }

    
    
  }

  partner = () => {

    this.setState({
      typeform: true,
      partner: true,
    })
  }

  klient = () => {
    this.setState({
      typeform: true,
      klient: true,
    })
  }

  pracownik = () => {
    this.setState({
      typeform: true,
      pracownik: true,
    })
  }

  

  
 

  render() {

    const Partner = () => {
      return <Widget id="lJl0qofl"  style={{ width: '100vw', height: 'calc(100vh + 10px)' }} className="my-form" />
    }

    const Klient = () => {
      return <Widget id="ZJ5uMnxp" style={{ width: '100vw', height: 'calc(100vh + 10px)' }} hideFooter={true} hideHeaders={true} className="my-form" />
    }

    const Pracownik = () => {
      return <Widget id="qtroqfuu"  style={{ width: '100vw', height: 'calc(100vh + 10px)' }} hideFooter={true} hideHeaders={true} className="my-form" />
    }

    const mobile = this.state.mobile

    let platony;

    const settings = {
      dots: false,
      speed: 300,
      
      infinite: false,
      centerPadding: "60px",
      arrows: false,
      slidesToShow: 1.5,
      rows: 1,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
    
          }
        },
      

    ],
      
   
  
    };

    
    
 
      if(mobile) {
        platony =   <div style={{position: 'relative'}}>
          <img src={strzalka} className="platony-strzalka" /><Slider style={{ minHeight: '300px'}} className="platony-mobile-slider" {...settings}>
         <div onClick={this.klient}>
           <img src={platon1} />
           <span className="przycisk">Mam projekt do zrobienia</span>
           
           
         </div>
         <div onClick={this.pracownik}>
           <img src={platon2} />
           <span className="przycisk">Chcę dołączyć do zespołu</span>
         </div>
         <div onClick={this.partner}>
           <img src={platon3} />
           <span className="przycisk">Mam ofertę współpracy</span>
         </div>
    
         
           </Slider>
           </div>
      }
      else {
        platony = <div className="formularz-platony">
        <div onClick={this.klient}>
           <img src={platon1} />
           <span className="przycisk">Mam projekt do zrobienia</span>
           
           
         </div>
         <div onClick={this.pracownik}>
           <img src={platon2} />
           <span className="przycisk">Chciałbym dołączyć do zespołu</span>
         </div>
         <div onClick={this.partner}>
           <img src={platon3} />
           <span className="przycisk">Mam ofertę współpracy</span>
         </div>
       </div>;
         
        
      }
  

    
   
    return(
   <div ref={this.check} className={this.state.typeform ? "typeform-aktywny" : ""}> <>
      <Seo title="Kontakt" description="Strategiczne podejście, wyrazista kreacja i precyzyjne wdrożenie na rynek. Skontaktuj się z nami - zaopiekujemy się Twoją marką!" image={kontakt} />
      <div className={this.state.wjazd ? "formularz-strona-wrapper formularz-wjazd" : "formularz-strona-wrapper"}>
        <div className="formularz-selektor">
        <TransitionLink
          to="/" className="formularz-wyjdz">
          <img src={zamknij} />
        </TransitionLink>
          <h2>CZEŚĆ,<br />
          W CZYM MOŻEMY<br />
          POMÓC?</h2>

         {platony}
         


      
        </div>

        <div  className="formularz-info">
          <div className="formularz-adres">
          <img src={telefon} />
          <p><a href="mailto: kontakt@odrastudio.com">kontakt@odrastudio.com</a><br />
          <a href="tel: +48 509 550 40">+48 509 550 409</a><br />
              Łokietka 7/4, 50-243 Wrocław<br />
              Pn-Pt: 10.00-18.00</p>
          <div className="formularz-social">
          <img src={fb} />
          <img src={ig} />

          </div>
         
          </div>
          <p>Jesteśmy wrocławskim studiem kreatywnym. Nasz zespół składa się ze specjalistów od marketingu, projektowania graficznego i mediów. Rozmywamy granice między sztuką i biznesem, skupiając się na ludziach i ich doświadczeniach. Dzięki strategicznemu podejściu, wyrazistej kreacji i precyzyjnemu wdrożeniu na rynek, angażujemy i budujemy więzi z użytkownikami. Skontaktuj się z nami - zaopiekujemy się Twoją marką!</p>

          <a target="_blank" className="formularz-mapa" href="https://www.google.com/maps/place/Odra/@51.1186866,17.0335607,16.48z/data=!4m5!3m4!1s0x0:0xd7d551b7518961f2!8m2!3d51.1183319!4d17.035512">
            <img src={mapa} />
          </a>

          <TransitionLink to="/polityka-prywatnosci"><span className="przycisk">REGULAMIN I POLITYKA PRYWATNOŚCI</span></TransitionLink>


        </div>


      
        
         

       
      </div>
      <div>
      <div className={this.state.typeform ? "typeform-wjazd type" : "type"} ref={(el) => this.el = el} style={{position: 'fixed', width: '100vw', top: '0', left: '0', height: '100vh', zIndex: '100000000', background: '#FEDD00'}}>
        <div className="informacja-formularz"><p>Aby cofnąć do poprzedniego pytania użyj scrolla na myszce.</p></div>
        <div style={this.state.klient ? {} : {display: 'none'}}>
          {this.state.wjazd && <Klient  />}
        
        </div>
        <div style={this.state.pracownik ? {} : {display: 'none'}} >
        {this.state.wjazd && <Pracownik />}
        
        </div>

        <div style={this.state.partner ? {} : {display: 'none'}}>
        {this.state.wjazd && <Partner  />}
        
        </div>
        
        
      
      </div>
      <TransitionLink
      style={this.state.typeform ? {} : {display: 'none'}}
          to="/" className="formularz-wyjdz-aktywny">
          <img src={zamknij} />
        </TransitionLink>
      </div>  
      
    </>
    </div>
    )
    
  }
}

export default Formularz


